import React, { useEffect } from 'react';
import {Cloud, Database, File, Folder, Globe, Mail, Monitor, TrendingUp} from 'react-feather';
import WOW from 'wowjs';
import bookshelf from '../../assets/img/services-right-image/book-self.png';
import boxImage from '../../assets/img/services-right-image/box.png';
import chairImage from '../../assets/img/services-right-image/chair.png';
import cloudImage from '../../assets/img/services-right-image/cloud.png';
import cupImage from '../../assets/img/services-right-image/cup.png';
import flowerImage from '../../assets/img/services-right-image/flower-top.png';
import headphoneImage from '../../assets/img/services-right-image/head-phone.png';
import monitorImage from '../../assets/img/services-right-image/monitor.png';
import mugImage from '../../assets/img/services-right-image/mug.png';
import tableImage from '../../assets/img/services-right-image/table.png';
import tissueImage from '../../assets/img/services-right-image/tissue.png';
import waterBottleImage from '../../assets/img/services-right-image/water-bottle.png';
import wifiImage from '../../assets/img/services-right-image/wifi.png';
import circleShapeImage from '../../assets/img/services-right-image/cercle-shape.png';
import mainPicImage from '../../assets/img/services-right-image/main-pic.png';
import 'animate.css';
const CloudHostingServices = () => {
    useEffect(() => {
        const wow = new WOW.WOW({
            boxClass: 'wow', // default
            animateClass: 'animated', // default
            offset: 0, // default
            mobile: true, // default
            live: true, // default
        });
        wow.init();
    }, []);

    return (
        <div className="services-area ptb-80 bg-f7fafd">
            <div className="container">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-12 services-content">
                        <div className="section-title">
                            <h2>Cloud Hosting Services</h2>
                            <div className="bar"></div>
                            <p>We offers cutting-edge Cloud Hosting Services to enhance business
                                efficiency and productivity.</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="box">
                                    <i><Database/></i> Cloud Business Solutions
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="box">
                                    <i><Globe/></i> CRM System
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="box">
                                    <i><File/></i> ERP System
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="box">
                                    <i><TrendingUp/></i> Finance Systems
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="box">
                                    <i><Folder/></i> Accounting Systems
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="box">
                                    <i><Monitor/></i> E-Commerce Systems
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="box">
                                    <i><Mail/></i> IOT Solutions
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="box">
                                    <i><Cloud/></i> Cloud Agricultural Solutions
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 services-right-image">
                        <div>
                            <img src={bookshelf} className="wow fadeInDown" alt="book-self" data-wow-delay="0.6s" />
                            <img src={boxImage} className="wow fadeInUp" alt="box" data-wow-delay="0.6s" />
                            <img src={chairImage} className="wow fadeInLeft" alt="chair" data-wow-delay="0.6s" />
                            <img src={cloudImage} className="wow zoomIn" alt="cloud" data-wow-delay="0.6s" />
                            <img src={cupImage} className="wow bounceIn" alt="cup" data-wow-delay="0.6s" />
                            <img src={flowerImage} className="wow fadeInDown" alt="flower" data-wow-delay="0.6s" />
                            <img src={headphoneImage} className="wow zoomIn" alt="head-phone" data-wow-delay="0.6s" />
                            <img src={monitorImage} className="wow fadeInUp" alt="monitor" data-wow-delay="0.6s" />
                            <img src={mugImage} className="wow rotateIn" alt="mug" data-wow-delay="0.6s" />
                            <img src={tableImage} className="wow fadeInUp" alt="table" data-wow-delay="0.6s" />
                            <img src={tissueImage} className="wow zoomIn" alt="tissue" data-wow-delay="0.6s" />
                            <img src={waterBottleImage} className="wow zoomIn" alt="water-bottle" data-wow-delay="0.6s" />
                            <img src={wifiImage} className="wow fadeInLeft" alt="wifi" data-wow-delay="0.6s" />
                            <img src={circleShapeImage} className="bg-image rotateme" alt="shape" />
                            <img src={mainPicImage} className="wow fadeInUp" alt="main-pic" data-wow-delay="0.6s" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CloudHostingServices;
