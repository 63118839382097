import React from 'react';
import { Link } from 'react-router-dom';

const ServiceBox = ({ image, title, linkTo }) => {
    return (
        <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="agency-services-box">
                <img src={image} alt={title} />

                <div className="content">
                    <h3><Link to={linkTo}>{title}</Link></h3>
                    <Link to={linkTo} className="read-more-btn">Read More <i data-feather="plus-circle"></i></Link>
                </div>
            </div>
        </div>
    );
}

export default ServiceBox;
