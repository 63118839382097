import React from 'react';
import {Users, Code, Server, GitBranch} from 'react-feather';


const BoxesArea = () => {
    return (
        <div className="boxes-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-box">
                            <div className="icon">
                                <Server/>
                            </div>
                            <h3>Zero Configuration</h3>
                            <p>Streamlining IT setups effortlessly for maximum efficiency and productivity.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-box bg-f78acb">
                            <div className="icon">
                                <Code/>
                            </div>
                            <h3>Code Security</h3>
                            <p>Safeguarding digital assets with robust encryption and stringent access controls.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-box bg-c679e3">
                            <div className="icon">
                                <Users />
                            </div>
                            <h3>Team Management</h3>
                            <p>Empowering teams with effective leadership and collaborative strategies to achieve
                                success.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-box bg-eb6b3d">
                            <div className="icon">
                                <GitBranch />
                            </div>
                            <h3>Access Controlled</h3>
                            <p>Ensuring secure and efficient data access for enhanced business operations.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoxesArea;
