import React, { useState } from 'react';
import Navbar from '../layout/basic-components/Navbar';
import PageTitle from '../layout/mini-components/service/PageTitle';
import Footer from '../layout/basic-components/Footer';
import Modal from 'react-modal'; // Import react-modal
import mlProjectsImg1 from '../assets/img/projects-images/2.png';
import mlProjectsImg2 from '../assets/img/projects-images/3.png';
import mlProjectsImg3 from '../assets/img/projects-images/4.png';
import mlProjectsImg4 from '../assets/img/projects-images/5.png';
import mlProjectsImg5 from '../assets/img/projects-images/6.png';
import mlProjectsImg6 from '../assets/img/projects-images/7.png';
import mlProjectsImg7 from '../assets/img/projects-images/8.png';
import mlProjectsImg8 from '../assets/img/projects-images/9.png';
import { Link } from 'react-router-dom';

const projectImages = [
    mlProjectsImg1,
    mlProjectsImg2,
    mlProjectsImg3,
    mlProjectsImg4,
    mlProjectsImg5,
    mlProjectsImg6,
    mlProjectsImg7,
    mlProjectsImg8,
];

const Project = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (image) => {
        setSelectedImage(image);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setModalIsOpen(false);
    };

    return (
        <>
            <Navbar />
            <PageTitle title={'Our Projects'} />
            <div className="ml-projects-area ptb-80">
                <div className="container-fluid">
                    <div className="row">
                        {projectImages.map((image, index) => (
                            <div key={index} className="col-lg-3 col-sm-6 col-md-6">
                                <div className="single-ml-projects-box">
                                    <img
                                        src={image}
                                        alt={`Project ${index + 1}`}
                                        onClick={() => openModal(image)} // Open modal on click
                                    />

                                    <div className="plus-icon">
                                        <Link to="/" onClick={(e) => { e.preventDefault(); openModal(image); }}>
                                            <span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />

            {/* Modal for zoomed image */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="zoomed-image-modal"
                overlayClassName="zoomed-image-overlay"
            >
                {selectedImage && (
                    <img src={selectedImage} alt="Zoomed Image" />
                )}
                <button onClick={closeModal}>Close</button>
            </Modal>
        </>
    );
};

export default Project;
