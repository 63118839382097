import React from 'react';
import { Link } from 'react-router-dom';
import blogData from '../../../assets/json/BlogPostData.json';
import ArticleImage from '../../../assets/img/blog-image/1.jpg';
import {Check, Clock, User} from "react-feather";
import BlogSidebar from "./BlogSidebar";

const BlogDetails = () => {
    const {
        image,
        date,
        author,
        title,
        content,
        galleryImages,
        features,
        tags,
    } = blogData.blogPost;

    return (
        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img src={ArticleImage} alt="image" />
                            </div>
                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Clock/> {date}
                                        </li>
                                        <li>
                                            <User/> {author}
                                        </li>
                                    </ul>
                                </div>
                                <h3>{title}</h3>
                                {content.map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))}
                                <blockquote className="wp-block-quote">
                                    <p>It is a long established fact that a reader will be distracted by the readable
                                        content of a page when looking at its layout.</p>

                                    <cite>Tom Cruise</cite>
                                </blockquote>
                                {/* Gallery */}
                                <ul className="wp-block-gallery columns-3">
                                    {galleryImages.map((image, index) => (
                                        <li key={index} className="blocks-gallery-item">
                                            <figure>
                                                <img src={image} alt="image" />
                                            </figure>
                                        </li>
                                    ))}
                                </ul>
                                {/* Features */}
                                <h3>Four major elements that we offer:</h3>
                                <ul className="features-list">
                                    {features.map((feature, index) => (
                                        <li key={index}>
                                            <Check/> {feature}
                                        </li>
                                    ))}
                                </ul>
                                {/* Tags */}
                                <div className="article-footer">
                                    <div className="article-tags">
                                        {tags.map((tag, index) => (
                                            <Link key={index} to={`/tags/${tag.toLowerCase()}`}>
                                                {tag}
                                            </Link>
                                        ))}
                                    </div>
                                </div>

                                {/*prev next section*/}
                                <div className="startp-post-navigation">
                                    <div className="prev-link-wrapper">
                                        <div className="info-prev-link-wrapper">
                                            <a href="#">
                                            <span className="image-prev">
                                                <img src="/assets/img/blog-image/2.jpg" alt="image"/>
                                                <span className="post-nav-title">Prev</span>
                                            </span>
                                                <span className="prev-link-info-wrapper">
                                                <span className="prev-title">Don't buy a tech gift until you read these rules</span>
                                                <span className="meta-wrapper">
                                                    <span className="date-post">January 21, 2021</span>
                                                </span>
                                            </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="next-link-wrapper">
                                        <div className="info-next-link-wrapper">
                                            <a href="#">
                                            <span className="next-link-info-wrapper">
                                                <span
                                                    className="next-title">The golden rule of buying a phone as a gift</span>
                                                <span className="meta-wrapper">
                                                    <span className="date-post">January 21, 2021</span>
                                                </span>
                                            </span>

                                                <span className="image-next">
                                                <img src="/assets/img/blog-image/3.jpg" alt="image"/>
                                                <span className="post-nav-title">Next</span>
                                            </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {/*comments section*/}
                                <div className="comments-area">
                                    <h3 className="comments-title">2 Comments:</h3>

                                    <ol className="comment-list">
                                        <li className="comment">
                                            <article className="comment-body">
                                                <footer className="comment-meta">
                                                    <div className="comment-author vcard">
                                                        <img src="/assets/img/client-image/1.jpg" className="avatar"
                                                             alt="image"/>
                                                            <b className="fn">John Jones</b>
                                                            <span className="says">says:</span>
                                                    </div>

                                                    <div className="comment-metadata">
                                                        <a href="#">
                                                            <time>April 24, 2019 at 10:59 am</time>
                                                        </a>
                                                    </div>
                                                </footer>

                                                <div className="comment-content">
                                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever
                                                        since the 1500s, when an unknown printer took a galley of type
                                                        and scrambled it to make a type specimen.</p>
                                                </div>

                                                <div className="reply">
                                                    <a href="#" className="comment-reply-link">Reply</a>
                                                </div>
                                            </article>
                                        </li>
                                    </ol>

                                    <div className="comment-respond">
                                        <h3 className="comment-reply-title">Leave a Reply</h3>

                                        <form className="comment-form">
                                            <p className="comment-notes">
                                                <span id="email-notes">Your email address will not be published.</span>
                                                Required fields are marked
                                                <span className="required">*</span>
                                            </p>
                                            <p className="comment-form-comment">
                                                <label>Comment</label>
                                                <textarea name="comment" id="comment" cols="45" rows="5"
                                                          maxLength="65525" required="required"></textarea>
                                            </p>
                                            <p className="comment-form-author">
                                                <label>Name <span className="required">*</span></label>
                                                <input type="text" id="author" name="author" required="required"/>
                                            </p>
                                            <p className="comment-form-email">
                                                <label>Email <span className="required">*</span></label>
                                                <input type="email" id="email" name="email" required="required"/>
                                            </p>
                                            <p className="comment-form-url">
                                                <label>Website</label>
                                                <input type="url" id="url" name="url"/>
                                            </p>
                                            <p className="comment-form-cookies-consent">
                                                <input type="checkbox" value="yes" name="wp-comment-cookies-consent"
                                                       id="wp-comment-cookies-consent"/>
                                                    <label htmlFor="wp-comment-cookies-consent">Save my name, email, and
                                                        website in this browser for the next time I comment.</label>
                                            </p>
                                            <p className="form-submit">
                                                <input type="submit" name="submit" id="submit" className="submit"
                                                       value="Post Comment"/>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Sidebar */}
                    <div className="col-lg-4 col-md-12">
                        {/* Sidebar content */}
                      <BlogSidebar/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;
