import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import TestimonialImage1 from '../../assets/img/client-image/1.jpg';
import TestimonialImage2 from '../../assets/img/client-image/2.jpg';
import TestimonialImage3 from '../../assets/img/client-image/3.jpg';
import {Star} from "react-feather";

const Testimonials = () => {
    // Testimonials data
    const testimonials = [
        {
            content: "I appreciate the prompt response from your customer support team. It shows your commitment to " +
                "excellent service.",
            image: TestimonialImage1,
            name: "Sarah Taylor",
            position: "From USA",
        },
        {
            content: "I appreciate the prompt response from your customer support team. It shows your commitment to " +
                "excellent service.",
            image: TestimonialImage2,
            name: "Steven Smith",
            position: "From NewZealand",
        },
        {
            content: "I appreciate the prompt response from your customer support team. It shows your commitment to " +
                "excellent service.",
            image: TestimonialImage3,
            name: "James Eva",
            position: "From UK",
        },
    ];

    return (
        <div className="ml-feedback-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">Testimonials</span>
                    <h2>Our Clients Feedback</h2>
                    <div className="bar"></div>
                    <p>Client feedback is invaluable for improving products and services. Gather feedback regularly,
                        analyze trends, and take actionable steps to address customer needs and enhance their
                        experience.</p>
                </div>

                <OwlCarousel className="ml-feedback-slides owl-carousel owl-theme" items={1} loop autoplay dots={true}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="single-testimonials-item border-0">
                            <p>{testimonial.content}</p>
                            <div className="client-info">
                                <img src={testimonial.image} alt="Client" />
                                <div className="rating">
                                    {[...Array(5)].map((_, i) => (
                                        <i><Star key={i}/></i>
                                    ))}
                                </div>
                                <h3>{testimonial.name}</h3>
                                <span>{testimonial.position}</span>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>

            {/* User shapes */}
            {[1, 2, 3, 4, 5, 6].map((num) => (
                <div key={num} className={`user-shape${num}`}>
                    <img src={`/assets/img/agency-image/user${num}.png`} alt={`User ${num}`} />
                </div>
            ))}
        </div>
    );
};

export default Testimonials;
