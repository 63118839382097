import React from 'react';
import Navbar from "../../layout/basic-components/Navbar";
import PageTitle from "../../layout/mini-components/service/PageTitle";
import Footer from "../../layout/basic-components/Footer";
import ServiceBox from "../../layout/mini-components/service/ServiceBox";
import serviceData from '../../assets/json/ServiceData.json';

function Service() {
    return (
        <>
            <Navbar/>
            <PageTitle title={'Our Services'}/>
            <div className="agency-services-area ptb-80">
                <div className="container">
                    <div className="row">
                        {serviceData.map(service => (
                            <ServiceBox
                                key={service.id}
                                image={service.image}
                                title={service.title}
                                linkTo={`/service${service.id}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Service;
