import React from 'react';
import aboutImage from '../../../assets/img/about4.png';
import {Link} from "react-router-dom";

const AboutCard = () => {
    return (
        <div className="about-area ptb-80">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-img">
                            <img src={aboutImage} alt="About" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-content">
                            <span className="sub-title">About Us</span>
                            <h2>Engaging New Audiences Through Smart Approach</h2>
                            <div className="bar"></div>
                            <p>We have been developing software for customers in Sri Lanka, India and Australia for
                                over 7+ years and now offer our services through 4 major software. Namely SimpCash,
                                HotelMate, TourMate and BizCall. We also design software according to customer
                                requirements.<br/>
                                We have so far developed more than 27 projects. These include software such as Hotel
                                Management, Travel Agency Management, Micro Finance, E-Commerce, Call Center CRM,
                                Delivery Management, Online Payments and Business Websites.
                                We currently provide our service to more than 20 customers and more than 450 users daily
                                use our software's.</p>

                            <Link to="contact" className="btn btn-secondary">Discover More</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our History</h3>
                                <p>With a legacy of over 5 years, we have been crafting software solutions for clients
                                    across Sri Lanka, India, and Australia, offering versatile services through
                                    platforms like SimpCash, HotelMate, TourMate, and BizCall.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>Our mission is to design and deliver tailored software solutions that cater to the
                                    unique needs of our diverse clientele, ensuring efficiency, innovation, and seamless
                                    user experiences.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Who we are</h3>
                                <p>We are a dynamic software development company, specializing in a range of solutions
                                    from Hotel Management and E-Commerce to Call Center CRM and Online Payments,
                                    serving over 20 satisfied customers with a daily user base exceeding 450 across our
                                    platforms.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AboutCard;
