import React, { useEffect, useRef } from 'react';
import mapImage from '../../assets/img/map.png';
import Odometer from 'odometer';
import { Link } from 'react-router-dom';

const FunFactsArea = () => {
    const countriesRef = useRef(null);
    const companiesRef = useRef(null);
    const usersRef = useRef(null);
    const projectsRef = useRef(null);

    useEffect(() => {
        const countriesOdometer = new Odometer({
            el: countriesRef.current,
            value: 0,
            format: '(,ddd)',
        });
        countriesOdometer.update(10);

        const companiesOdometer = new Odometer({
            el: companiesRef.current,
            value: 0,
            format: '(,ddd)',
        });
        companiesOdometer.update(16);

        const usersOdometer = new Odometer({
            el: usersRef.current,
            value: 0,
            format: '(,ddd)',
        });
        usersOdometer.update(250);

        const projectsOdometer = new Odometer({
            el: projectsRef.current,
            value: 0,
            format: '(,ddd)',
        });
        projectsOdometer.update(26);

        return () => {
            // Clean up Odometer instances if needed
        };
    }, []);

    return (
        <div className="funfacts-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>We always try to understand users' expectations</h2>
                    <div className="bar"></div>
                    <p>We continuously strive to comprehend and meet users' expectations to ensure satisfaction and deliver exceptional experiences.</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-3 col-6 col-sm-3">
                        <div className="funfact">
                            <h3 ref={countriesRef}><span className="odometer">00</span>K</h3>
                            <p>Countries</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6 col-sm-3">
                        <div className="funfact">
                            <h3 ref={companiesRef}><span className="odometer">00</span>K</h3>
                            <p>Companies</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6 col-sm-3">
                        <div className="funfact">
                            <h3 ref={usersRef}><span className="odometer">00</span>+</h3>
                            <p>Users</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6 col-sm-3">
                        <div className="funfact">
                            <h3 ref={projectsRef}><span className="odometer">00</span>+</h3>
                            <p>Completed Projects</p>
                        </div>
                    </div>
                </div>

                <div className="contact-cta-box">
                    <h3>Have any questions about us?</h3>
                    <p>Don't hesitate to contact us</p>
                    <Link to="/contact" className="btn btn-primary">Contact Us</Link>
                </div>

                <div className="map-bg">
                    <img src={mapImage} alt="map" />
                </div>
            </div>
        </div>
    );
}

export default FunFactsArea;
