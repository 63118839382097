import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Linkedin, Mail, MapPin, PhoneCall } from 'react-feather';
import logo from '../../assets/img/pixous-logo.png';
import map from '../../assets/img/map.png';
import shape1Image from '../../assets/img/shape1.png';
import shape2Image from '../../assets/img/shape2.svg';

const Footer = () => {
    return (
        <footer className="footer-area bg-f7fafd">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="logo black-logo">
                                <Link to="/"><img src={logo} alt="logo" style={{ width: '150px', height: '30px' }} /></Link>
                            </div>
                            <p>Provide customizable business solutions as your wish and provide innovative solutions for simply manage your business</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Company</h3>
                            <ul className="list">
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/features">Features</Link></li>
                                <li><Link to="/pricing">Our Pricing</Link></li>
                                <li><Link to="/blog">Latest News</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Support</h3>
                            <ul className="list">
                                <li><Link to="/faq">FAQ's</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/terms-conditions">Terms & Condition</Link></li>
                                <li><Link to="/contact">Support Us</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Address</h3>
                            <ul className="footer-contact-info">
                                <li><MapPin /> 263/E1, Nugewala, Mamadala, Ambalantota, Sri Lanka</li>
                                <li><Mail />
                                    Email: <a href="mailto:pixousinnovations@gmail.com"><span className="__cf_email__" data-cfemail="b7dfd2dbdbd8f7c4c3d6c5c3c799d4d8da">pixousinnovations@gmail.com</span></a></li>
                                <li><PhoneCall /> Phone: <a href="tel:+94771010064">+94 77 10 100 64</a></li>
                            </ul>
                            <ul className="social-links ">
                                <li><Link to="https://web.facebook.com/pixousinnovations/?_rdc=1&_rdr" className="facebook me-1" target="_blank"><Facebook /></Link></li>
                                <li><Link to="https://www.linkedin.com/company/pixous-innovations/" className="linkedin me-1" target="_blank"><Linkedin /></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            <p>Copyright @{new Date().getFullYear()} All Rights Reserved by PIXOUS INNOVATIONS</p>
                        </div>
                    </div>
                </div>
            </div>

            <img src={map} className="map" alt="map" />
            <div className="shape1"><img src={shape1Image} alt="shape" /></div>
            <div className="shape8 rotateme"><img src={shape2Image} alt="shape" /></div>
        </footer>
    );
};

export default Footer;
