import React, {useEffect} from 'react';
import manImage from '../../assets/img/banner-image/man.png';
import codeImage from '../../assets/img/banner-image/code.png';
import carpetImage from '../../assets/img/banner-image/carpet.png';
import binImage from '../../assets/img/banner-image/bin.png';
import bookImage from '../../assets/img/banner-image/book.png';
import desktopImage from '../../assets/img/banner-image/dekstop.png';
import dotImage from '../../assets/img/banner-image/dot.png';
import flowerBigImage from '../../assets/img/banner-image/flower-top-big.png';
import flowerImage from '../../assets/img/banner-image/flower-top.png';
import keyboardImage from '../../assets/img/banner-image/keyboard.png';
import penImage from '../../assets/img/banner-image/pen.png';
import tableImage from '../../assets/img/banner-image/table.png';
import teaCupImage from '../../assets/img/banner-image/tea-cup.png';
import headphoneImage from '../../assets/img/banner-image/headphone.png';
import mainPicImage from '../../assets/img/banner-image/main-pic.png';
import shape1Image from '../../assets/img/shape1.png';
import shape2Image from '../../assets/img/shape2.svg';
import shape3Image from '../../assets/img/shape3.svg';
import shape4Image from '../../assets/img/shape4.svg';
import shape5Image from '../../assets/img/shape5.png';
import WOW from 'wowjs';
import 'animate.css';
import {Link} from "react-router-dom";


const MainBanner = () => {

        useEffect(() => {
            const wow = new WOW.WOW({
                boxClass: 'wow', // default
                animateClass: 'animated', // default
                offset: 0, // default
                mobile: true, // default
                live: true, // default
            });
            wow.init();
        }, []);

        return (
        <div className="main-banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row h-100 justify-content-center align-items-center">
                            <div className="col-lg-5">
                                <div className="hero-content">
                                    <h1>Cloud, Business, Solutions</h1>
                                    <p>Pixous Innovations specializes in cloud-based IT solutions designed to optimize
                                        business operations and boost productivity. With a focus on leveraging advanced
                                        technologies and over 5 years of industry experience, we deliver tailored
                                        solutions that meet the evolving needs of our clients.</p>

                                    <Link to='/' className="btn btn-primary">Get Started</Link>
                                </div>
                            </div>

                            <div className="col-lg-6 offset-lg-1">
                                <div className="banner-image">
                                    <img src={manImage} className="wow fadeInDown" data-wow-delay="0.6s" alt="man" />
                                    <img src={codeImage} className="wow fadeInUp" data-wow-delay="0.6s" alt="code" />
                                    <img src={carpetImage} className="wow fadeInLeft" data-wow-delay="0.6s" alt="carpet" />
                                    <img src={binImage} className="wow zoomIn" data-wow-delay="0.6s" alt="bin" />
                                    <img src={bookImage} className="wow bounceIn" data-wow-delay="0.6s" alt="book" />
                                    <img src={desktopImage} className="wow fadeInDown" data-wow-delay="0.6s" alt="desktop" />
                                    <img src={dotImage} className="wow zoomIn" data-wow-delay="0.6s" alt="dot" />
                                    <img src={flowerBigImage} className="wow fadeInUp" data-wow-delay="0.6s" alt="flower-big" />
                                    <img src={flowerImage} className="wow rotateIn" data-wow-delay="0.6s" alt="flower" />
                                    <img src={keyboardImage} className="wow fadeInUp" data-wow-delay="0.6s" alt="keyboard" />
                                    <img src={penImage} className="wow zoomIn" data-wow-delay="0.6s" alt="pen" />
                                    <img src={tableImage} className="wow zoomIn" data-wow-delay="0.6s" alt="table" />
                                    <img src={teaCupImage} className="wow fadeInLeft" data-wow-delay="0.6s" alt="tea-cup" />
                                    <img src={headphoneImage} className="wow rollIn" data-wow-delay="0.6s" alt="headphone" />
                                    <img src={mainPicImage} className="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="shape1"><img src={shape1Image} alt="shape" /></div>
            <div className="shape2 rotateme"><img src={shape2Image} alt="shape" /></div>
            <div className="shape3"><img src={shape3Image} alt="shape" /></div>
            <div className="shape4"><img src={shape4Image} alt="shape" /></div>
            <div className="shape5"><img src={shape5Image} alt="shape" /></div>
            <div className="shape6 rotateme"><img src={shape4Image} alt="shape" /></div>
            <div className="shape7"><img src={shape4Image} alt="shape" /></div>
            <div className="shape8 rotateme"><img src={shape2Image} alt="shape" /></div>
        </div>
    );
};

export default MainBanner;
