import React from 'react';
import Navbar from "../../layout/basic-components/Navbar";
import Footer from "../../layout/basic-components/Footer";
import PageTitle from "../../layout/mini-components/service/PageTitle";
import BlogDetail from "../../layout/mini-components/home/BlogDetail";

function SingleBlog() {
    return (
        <>
            <Navbar/>
            <PageTitle title={'The security risks of changing package owners'}/>
            <BlogDetail/>
            <Footer/>
        </>
    );
}

export default SingleBlog;
