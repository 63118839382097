import React from 'react';
import { Link } from 'react-router-dom';
import BigDataIcon1 from '../../../assets/img/bigdata-analytics/icon1.png';
import BigDataIcon2 from '../../../assets/img/bigdata-analytics/icon2.png';
import BigDataIcon3 from '../../../assets/img/bigdata-analytics/icon3.png';
import BigDataIcon4 from '../../../assets/img/bigdata-analytics/icon4.png';
import BigDataIcon5 from '../../../assets/img/bigdata-analytics/icon5.png';
import BigDataIcon6 from '../../../assets/img/bigdata-analytics/icon6.png';
import BigDataIcon7 from '../../../assets/img/bigdata-analytics/rectangle.png';
import {ArrowRight} from "react-feather";

const CareerCards = () => {
    const servicesData = [
        {
            icon: BigDataIcon1,
            title: 'Full-Stack Developer (ReactJS & Spring Boot)',
            type: 'Onsite - Full-Time - Colombo,Sri Lanka',
            link: '/job1'
        },
        {
            icon: BigDataIcon2,
            title: 'UI/UX Designer',
            type: 'Remote - Full-Time - Colombo,Sri Lanka',
            link: '/job1'
        },
        {
            icon: BigDataIcon3,
            title: 'Business Analysis',
            type: 'Hybrid - Full-Time - Colombo,Sri Lanka',
            link: '/job1'
        },
        {
            icon: BigDataIcon4,
            title: 'Quality Assurance',
            type: 'Remote - Part-Time - Colombo,Sri Lanka',
            link: '/job1'
        },
        {
            icon: BigDataIcon5,
            title: 'Project Manager',
            type: 'Onsite - Full-Time - Colombo,Sri Lanka',
            link: '/job1'
        },
        {
            icon: BigDataIcon6,
            title: 'Digital Marketing (Intern)',
            type: 'Onsite - Full-Time - Colombo,Sri Lanka',
            link: '/job1'
        },
    ];

    return (
        <div className="bigdata-services-area ptb-80 bg-eef6fd">
            <div className="container">
                <div className="section-title">
                    <h2>New Openings</h2>
                    <div className="bar"></div>

                </div>

                <div className="row">
                    {servicesData.map((service, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services-box-item">
                                <div className="icon">
                                    <img src={service.icon} alt="icon" />
                                </div>
                                <h3><Link to={service.link}>{service.title}</Link></h3>
                                <p>{service.type}</p>
                                <Link to={service.link} className="learn-more-btn"><ArrowRight/>Apply Now</Link>
                                <div className="shape"><img src={BigDataIcon7} alt="shape" /></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CareerCards;
