import React from 'react';
import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';
import blogData from '../../assets/json/BlogData.json';

const BlogArea = () => {
    return (
        <div className="blog-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>The News from Our Blog</h2>
                    <div className="bar"></div>
                    <p>Stay updated with the latest news and insights from our blog.</p>
                </div>

                <div className="row">
                    {blogData.map((blog) => (
                        <div key={blog.id} className="col-lg-4 col-md-6">
                            <div className="single-blog-post">
                                <div className="blog-image">
                                    <Link to={`/blog${blog.id}`}>
                                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                        <img src={blog.image} alt="image" />
                                    </Link>
                                    <div className="date">
                                        <i data-feather="calendar"></i> {blog.date}
                                    </div>
                                </div>
                                <div className="blog-post-content">
                                    <h3>
                                        <Link to={`/blog${blog.id}`}>{blog.title}</Link>
                                    </h3>
                                    <span>by <Link to="#">{blog.author}</Link></span>
                                    <p>{blog.content}</p>
                                    <Link to={`/blog${blog.id}`} className="read-more-btn">
                                        Read More <i><ArrowRight /></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogArea;
