import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import teamImage1 from '../../assets/img/team-image/thisara.jpeg';
import teamImage2 from '../../assets/img/team-image/chathura.jpeg';
import teamImage3 from '../../assets/img/team-image/charith.jpeg';
import {Facebook, Gitlab, Linkedin, Twitter} from "react-feather";
import {Link} from "react-router-dom";

const TeamArea = () => {
    const teamMembers = [
        { id: 1, name: 'Thisara Priyamal', position: 'CEO & Founder',intro:'I am Thisara Priyamal. co-founder of pixous innovations. i have 6+ year experience in software industry and good domain experience about Tourism, Human Resources, Telecommunications, Finance and Accounting.', image: teamImage1 },
        { id: 2, name: 'Chathura Manoj', position: 'Senior Engineer', intro:'I am Chathura Manoj Hasaranga. Senior Engineer of pixous innovations. i have 3+ year experience in software industry and good domain experience about Workflow and Finance.',image: teamImage2 },
        { id: 3, name: 'Charith Wijethunga', position: 'Engineer',intro:'I am Charith Nuwan Wijethunga. Software Engineer of pixous innovations. i have 2+ year experience in software industry and good domain experience about Flight and Reservation.', image: teamImage3 }
    ];

    const teamSlides = teamMembers.map(member => (
        <div className="single-team" key={member.id}>
            <div className="team-image">
                <img src={member.image} alt={member.name} style={{ width: '130px', height: '130px' }} />
            </div>
            <div className="team-content">
                <div className="team-info">
                    <h3>{member.name}</h3>
                    <span>{member.position}</span>
                </div>
                <ul>
                    <li><Link to='/' target="_blank"><i><Facebook/></i></Link></li>
                    <li><Link to='/' target="_blank"><i><Twitter/></i></Link></li>
                    <li><Link to='/' target="_blank"><i><Linkedin/></i></Link></li>
                    <li><Link to='/' target="_blank"><i><Gitlab/></i></Link></li>
                </ul>
                <p>{member.intro}</p>
            </div>
        </div>
    ));

    return (
        <div className="team-area ptb-80 bg-f9f6f6">
            <div className="container">
                <div className="section-title">
                    <h2>Our Awesome Team</h2>
                    <div className="bar"></div>
                    <p>With our awesome team's creativity and innovation, we tackle challenges and exceed expectations</p>
                </div>
            </div>
            <div className="container p-0">
                <OwlCarousel
                    className="team-slides owl-carousel owl-theme"
                    loop
                    margin={20}
                    nav
                    responsive={{
                        0: { items: 1 },
                        768: { items: 2 },
                        1200: { items: 3 },
                    }}
                >
                    {teamSlides}
                </OwlCarousel>
            </div>
        </div>
    );
};

export default TeamArea;
