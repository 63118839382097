import React from 'react';
import javaLogo from '../../assets/img/partner-img/java-14.svg';
import javaScriptLogo from '../../assets/img/partner-img/javascript-1.svg';
import cssLogo from '../../assets/img/partner-img/css-3.svg';
import typeScriptLogo from '../../assets/img/partner-img/typescript.svg';
import reactLogo from '../../assets/img/partner-img/react-2.svg';
import springLogo from '../../assets/img/partner-img/spring-3.svg';
import nodeLogo from '../../assets/img/partner-img/partner-hover6.png';
import bootstrapLogo from '../../assets/img/partner-img/bootstrap-5-1.svg';
import tailwindLogo from '../../assets/img/partner-img/tailwind-css-2.svg';
import dockerLogo from '../../assets/img/partner-img/docker-3.svg';
import gitLogo from '../../assets/img/partner-img/github-icon-1-logo-svgrepo-com.svg';
import jenkinsLogo from '../../assets/img/partner-img/jenkins-1.svg';
import mongoDbLogo from '../../assets/img/partner-img/mongodb-icon-2.svg';
import mySqlLogo from '../../assets/img/partner-img/mysql-3.svg';
import linuxLogo from '../../assets/img/partner-img/linux-tux.svg';
import expressJsLogo from '../../assets/img/partner-img/logo-javascript.svg';
import htmlLogo from '../../assets/img/partner-img/html-1.svg';
import mariadbLogo from '../../assets/img/partner-img/mariadb.svg';
import bashLogo from '../../assets/img/partner-img/bash-2.svg';
import microsoftsqlLogo from '../../assets/img/partner-img/microsoft-sql-server-1.svg';
import figmaLogo from '../../assets/img/partner-img/figma-5.svg';
import firebaseLogo from '../../assets/img/partner-img/firebase-1.svg';
import jiraLogo from '../../assets/img/partner-img/jira-1.svg';
import postmanLogo from '../../assets/img/partner-img/postman.svg';

const PartnerArea = () => {
    // Define an array of partner images and their hover versions
    const partners = [
        { id: 1,  hoverImage: javaLogo, alt: 'java logo' },
        { id: 2,  hoverImage: javaScriptLogo, alt: 'javascript logo' },
        { id: 3,  hoverImage: cssLogo, alt: 'css logo' },
        { id: 4,  hoverImage: typeScriptLogo, alt: 'typeScript logo' },
        { id: 5,  hoverImage: reactLogo, alt: 'react logo' },
        { id: 6,  hoverImage: springLogo, alt: 'spring logo' },
        { id: 7,  hoverImage: nodeLogo, alt: 'node logo' },
        { id: 8,  hoverImage: jenkinsLogo, alt: 'jenkins logo' },
        { id: 9,  hoverImage: bootstrapLogo, alt: 'bootstrap logo' },
        { id: 10,  hoverImage: tailwindLogo, alt: 'tailwind logo' },
        { id: 11,  hoverImage: dockerLogo, alt: 'docker logo' },
        { id: 12,  hoverImage: gitLogo, alt: 'git logo' },
        { id: 13,  hoverImage: mongoDbLogo, alt: 'mongodb logo' },
        { id: 14,  hoverImage: mySqlLogo, alt: 'sql logo' },
        { id: 15,  hoverImage: linuxLogo, alt: 'linux logo' },
        { id: 16,  hoverImage: expressJsLogo, alt: 'expressjs logo' },
        { id: 17,  hoverImage: firebaseLogo, alt: 'firebase logo' },
        { id: 17,  hoverImage: htmlLogo, alt: 'html logo' },
        { id: 18,  hoverImage: mariadbLogo, alt: 'mariadb logo' },
        { id: 19,  hoverImage: bashLogo, alt: 'bash logo' },
        { id: 20,  hoverImage: microsoftsqlLogo, alt: 'microsoft sql logo' },
        { id: 22,  hoverImage: figmaLogo, alt: 'figma Logo' },
        { id: 23,  hoverImage: postmanLogo, alt: 'postman Logo' },
        { id: 24,  hoverImage: jiraLogo, alt: 'jira Logo' },
    ];

    return (
        <div className="partner-area partner-section">
            <div className="container">
                <h5>More than 27 businesses and organizations use Pixous Innovations</h5>
                <div className="partner-inner">
                    <div className="row pt-3 d-flex justify-content-center">
                        {/* Map through the partners array to render partner images */}
                        {partners.map(partner => (
                            <div key={partner.id} className="col-lg-2 col-md-3 col-6 col-sm-4 pb-4 ">
                                <img
                                    src={partner.hoverImage}
                                    alt={partner.alt}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PartnerArea;
