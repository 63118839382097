import React from 'react';
import {Mail, MapPin, Phone} from "react-feather";

class ContactInfoArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contactInfo: [
                {
                    icon:  <Mail />,
                    title: 'Mail Here',
                    info: [
                        { link: 'mailto:pixousinnovations@gmail.com', text: '\tpixousinnovations@gmail.com' },
                    ],
                },
                {
                    icon: <MapPin/>,
                    title: 'Visit Here',
                    info: [{ text: '263/E1, Nugewala, Mamadala, Ambalantota, Sri Lanka' }],
                },
                {
                    icon: <Phone/>,
                    title: 'Call Here',
                    info: [
                        { link: 'tel:+94 77 10 100 64', text: '+94 77 10 100 64' },
                    ],
                },
            ],
        };
    }

    render() {
        return (
            <div className="contact-info-area ptb-80">
                <div className="container">
                    <div className="row">
                        {this.state.contactInfo.map((item, index) => (
                            <div key={index} className="col-lg-4 col-md-6 col-sm-6">
                                <div className="contact-info-box">
                                    <div className="icon">{item.icon}</div>
                                    <h3>{item.title}</h3>
                                    {item.info.map((infoItem, infoIndex) => (
                                        <p key={infoIndex}><a href={infoItem.link}>{infoItem.text}</a></p>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactInfoArea;
