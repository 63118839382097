import React from 'react';
import {Cloud, Code, Layout, PenTool, Settings, Smartphone} from 'react-feather';
import bigMonitorImage from '../../assets/img/services-left-image/big-monitor.png';
import creativeImage from '../../assets/img/services-left-image/creative.png';
import developerImage from '../../assets/img/services-left-image/developer.png';
import flowerTopImage from '../../assets/img/services-left-image/flower-top.png';
import smallMonitorImage from '../../assets/img/services-left-image/small-monitor.png';
import smallTopImage from '../../assets/img/services-left-image/small-top.png';
import tableImage from '../../assets/img/services-left-image/table.png';
import targetImage from '../../assets/img/services-left-image/target.png';
import circleShapeImage from '../../assets/img/services-left-image/cercle-shape.png';
import mainPicImage from '../../assets/img/services-left-image/main-pic.png';

const DesignDevelopmentServices = () => {

    const services = [
        { icon: Layout, text: 'Responsive Design' },
        { icon: Code, text: 'React Web Development' },
        { icon: Smartphone, text: 'Microservices' },
        { icon: Code, text: 'Spring Boot Web Development' },
        { icon: PenTool, text: 'UX/UI Design' },
        { icon: Cloud, text: 'Cloud Hosting' },
        { icon: Settings, text: 'Continues Integration' },
        { icon: Settings, text: 'Continues Deployment' },
    ];


    return (
        <div className="services-area ptb-80">
            <div className="container">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-12 services-left-image">
                        <img src={bigMonitorImage} className="wow fadeInDown" alt="big-monitor" data-wow-delay="0.6s" />
                        <img src={creativeImage} className="wow fadeInUp" alt="creative" data-wow-delay="0.6s" />
                        <img src={developerImage} className="wow fadeInLeft" alt="developer" data-wow-delay="0.6s" />
                        <img src={flowerTopImage} className="wow zoomIn" alt="flower-top" data-wow-delay="0.6s" />
                        <img src={smallMonitorImage} className="wow bounceIn" alt="small-monitor" data-wow-delay="0.6s" />
                        <img src={smallTopImage} className="wow fadeInDown" alt="small-top" data-wow-delay="0.6s" />
                        <img src={tableImage} className="wow zoomIn" alt="table" data-wow-delay="0.6s" />
                        <img src={targetImage} className="wow fadeInUp" alt="target" data-wow-delay="0.6s" />
                        <img src={circleShapeImage} className="bg-image rotateme" alt="shape" />
                        <img src={mainPicImage} className="wow fadeInUp" alt="main-pic" data-wow-delay="0.6s" />
                    </div>

                    <div className="col-lg-6 col-md-12 services-content">
                        <div className="section-title">
                            <h2>Design & Development</h2>
                            <div className="bar"></div>
                            <p>Design & Development at Pixous Innovations is driven by creativity and expertise,
                                crafting innovative solutions to propel businesses forward.</p>
                        </div>

                        <div className="row">
                            {services.map((service, index) => (
                                <div key={index} className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="box">
                                        <service.icon /> {service.text}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DesignDevelopmentServices;
