import React from 'react';
import {Link} from "react-router-dom";

const ReadyToTalkArea = () => {
    return (
        <div className="ready-to-talk">
            <div className="container">
                <h3>Ready to talk?</h3>
                <p>Our team is here to answer your questions about StartP</p>
                <Link to='/' className="btn btn-primary">Contact Us</Link>
                <span><Link to="/">Or, get started now with a free trial</Link></span>
            </div>
        </div>
    );
}

export default ReadyToTalkArea;
