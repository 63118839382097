import React from 'react';
import PageTitle from "../../layout/mini-components/service/PageTitle";
import Footer from "../../layout/basic-components/Footer";
import CareerCards from "../../layout/mini-components/career/CareerCards";
import Navbar from "../../layout/basic-components/Navbar";
import AboutImg from '../../assets/img/agency-image/about-img.jpg';
import Shape2 from '../../assets/img/shape2.svg';
import Shape3 from '../../assets/img/shape3.svg';
import Shape4 from '../../assets/img/shape4.svg';
import Shape5 from '../../assets/img/shape4.svg';
import Shape6 from '../../assets/img/shape2.svg';
import Shape7 from '../../assets/img/agency-image/shape2.png';
import {Link} from "react-router-dom";

function Career() {
    return (
        <>
            <Navbar/>
            <PageTitle title={'Careers at Pixous Innovations'}/>
            <div className="agency-about-area">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="agency-about-img">
                                <img src={AboutImg} alt="About Us" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="agency-about-content">
                                <span className="sub-title">About Us</span>
                                <h2>Join Our Team</h2>
                                <div className="bar"></div>
                                <p>We’re an established company, but operate with the passion and speed of a start-up
                                    to challenge the status quo. <br/>Joining an open and fast-moving business means
                                    plenty of opportunities for you to advance your career at Pixous Innovations by
                                    taking on new and challenging responsibilities.</p>
                                <Link to="contact.html" className="btn btn-secondary">See Available Positions</Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Shapes */}
                <div className="shape2 rotateme"><img src={Shape2} alt="Shape 2" /></div>
                <div className="shape3"><img src={Shape3} alt="Shape 3" /></div>
                <div className="shape6 rotateme"><img src={Shape4} alt="Shape 4" /></div>
                <div className="shape7"><img src={Shape5} alt="Shape 5" /></div>
                <div className="shape8 rotateme"><img src={Shape6} alt="Shape 6" /></div>
                <div className="shape10"><img src={Shape7} alt="Shape 7" /></div>
            </div>
            <CareerCards/>
            <Footer/>
        </>
    );
}

export default Career;