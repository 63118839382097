import React from 'react';
import Navbar from "../layout/basic-components/Navbar";
import PageTitle from "../layout/mini-components/service/PageTitle";
import Footer from "../layout/basic-components/Footer";
import AboutCard from "../layout/mini-components/about/AboutCard";

function About() {
    return (
        <>
            <Navbar/>
            <PageTitle title={'Who We Are'}/>
            <AboutCard/>
            <Footer/>
        </>
    );
}

export default About;
