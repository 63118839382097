import React from 'react';
import { Link } from 'react-router-dom';
import {Search} from "react-feather";

const BlogSidebar = () => {
    // Sample data for widgets
    const categories = ['Business', 'Privacy', 'Technology', 'Tips', 'Uncategorized'];
    const archives = ['May 2019', 'April 2019', 'June 2019'];
    const tags = ['IT (3)', 'Spacle (3)', 'Games (2)', 'Fashion (2)', 'Travel (1)', 'Smart (1)', 'Marketing (1)', 'Tips (2)'];
    const popularPosts = [
        { id: 1, title: 'Making Peace With The Feast Or Famine Of Freelancing', date: 'June 10, 2019' },
        { id: 2, title: 'I Used The Web For A Day On A 50 MB Budget', date: 'June 21, 2019' },
        { id: 3, title: 'How To Create A Responsive Popup Gallery?', date: 'June 30, 2019' },
    ];

    return (
        <aside className="widget-area" id="secondary">
            {/* Search widget */}
            <div className="widget widget_search">
                <form className="search-form">
                    <label>
                        <span className="screen-reader-text">Search for:</span>
                        <input type="search" className="search-field" placeholder="Search..." />
                    </label>
                    <button type="submit"><Search/></button>
                </form>
            </div>

            {/* Popular Posts widget */}
            <div className="widget widget_startp_posts_thumb">
                <h3 className="widget-title">Popular Posts</h3>

                {popularPosts.map((post, index) => (
                    <article key={index} className="item">
                        <Link to={post.link} className="thumb">
                            <span className={`fullimage cover bg${index + 1}`} role="img"></span>
                        </Link>
                        <div className="info">
                            <time dateTime={post.date}>{post.date}</time>
                            <h4 className="title usmall"><Link to={post.link}>{post.title}</Link></h4>
                        </div>
                        <div className="clear"></div>
                    </article>
                ))}
            </div>

            {/* Categories widget */}
            <div className="widget widget_categories">
                <h3 className="widget-title">Categories</h3>
                <ul>
                    {categories.map(category => (
                        <li key={category}><Link to={`/category/${category}`}>{category}</Link></li>
                    ))}
                </ul>
            </div>

            {/* Archives widget */}
            <div className="widget widget_archive">
                <h3 className="widget-title">Archives</h3>
                <ul>
                    {archives.map(archive => (
                        <li key={archive}><Link to={`/archive/${archive}`}>{archive}</Link></li>
                    ))}
                </ul>
            </div>

            {/* Tags widget */}
            <div className="widget widget_tag_cloud">
                <h3 className="widget-title">Tags</h3>
                <div className="tagcloud">
                    {tags.map(tag => (
                        <Link key={tag} to={`/tag/${tag}`}>{tag}</Link>
                    ))}
                </div>
            </div>
        </aside>
    );
};

export default BlogSidebar;
