import React from 'react';
import { Bell } from 'react-feather';
import { Link } from 'react-router-dom';
import logoImage from '../../assets/img/pixous-logo.png';
import serviceData from '../../assets/json/ServiceData.json'
import worksData from '../../assets/json/WorksData.json'

const Navbar = () => {
    return (
        <>
            <header id="header" className="headroom">
                {/* Start Navbar Area */}
                <div className="startp-responsive-nav">
                    <div className="container">
                        <div className="startp-responsive-menu">
                            <div className="logo white-logo">
                                <img src={logoImage} alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="startp-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <Link className="navbar-brand black-logo" to="/">
                                <img src={logoImage} alt="logo" style={{ width: '150px', height: '30px' }} />
                            </Link>

                            {/* Navbar Toggler */}
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            {/* Navbar Items */}
                            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul className="navbar-nav nav ml-auto">
                                    <li className="nav-item"><Link to="/" className="nav-link active">Home <i
                                        data-feather="chevron-down"></i></Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/service" className="nav-link">Services</Link>
                                        <ul className="dropdown_menu">
                                            {serviceData.map((service) => (
                                                <li className="nav-item"><Link to={`/service${service.id}`} className="nav-link">{service.title}</Link></li>
                                            ))}
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/projects" className="nav-link">Projects <i data-feather="chevron-down"></i></Link>
                                    </li>

                                    <li className="nav-item"><Link to="/career" className="nav-link">Career</Link></li>

                                    <li className="nav-item"><Link to="/about" className="nav-link">About <i data-feather="chevron-down"></i></Link>
                                    </li>
                                    <li className="nav-item"><Link to="/contact" className="nav-link">Contact</Link></li>
                                </ul>

                            </div>

                            <div className="others-option">
                                <Link to="cart" className="cart-wrapper-btn"><Bell /><span>5</span></Link>
                                <Link to="login" className="btn btn-primary">Support</Link>

                               {/* dark mode
                                <div className="dark-version">
                                    <label id="switch" className="switch">
                                        <input type="checkbox" onChange="toggleTheme()" id="slider"/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>*/}

                            </div>
                        </nav>
                    </div>
                </div>

                {/* Responsive Options */}
                <div className="others-option-for-responsive">
                    <div className="container">
                        {/* Responsive Dot Menu */}
                        <div className="dot-menu">
                            <div className="inner">
                                <div className="circle circle-one"></div>
                                <div className="circle circle-two"></div>
                                <div className="circle circle-three"></div>
                            </div>
                        </div>

                        {/* Responsive Other Options */}
                        <div className="container">
                            <div className="option-inner">
                                <div className="others-option">
                                    <Link to="cart" className="cart-wrapper-btn">
                                        <Bell />
                                        <span>0</span>
                                    </Link>
                                    <Link to="login" className="btn btn-primary">Support</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Navbar Area */}
            </header>
        </>
    );
};
export default Navbar;
