import React from 'react';

const Map = () => {
    return (
        <div id="map" className="map-container">
            <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63466.77282921488!2d80.91938694478378!3d6.174474566058091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae6af7a521990cd%3A0xe288cf2253612b6d!2sPIXOUS%20INNOVATIONS!5e0!3m2!1sen!2slk!4v1711391737236!5m2!1sen!2slk"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
            ></iframe>
        </div>
    );
};

export default Map;
