import React from 'react';
import Navbar from "../layout/basic-components/Navbar";
import PageTitle from "../layout/mini-components/service/PageTitle";
import Footer from "../layout/basic-components/Footer";
import ContactInfoArea from "../layout/mini-components/contact/ContactInfo";
import Map from "../layout/mini-components/contact/Map";
import ContactForm from "../layout/mini-components/contact/ContactForm";

function Contact() {
    return (
        <>
            <Navbar/>
            <PageTitle title={'Contact Us'}/>
            <ContactInfoArea/>
            <Map/>
            <ContactForm/>
            <Footer/>
        </>
    );
}

export default Contact;
