import React from 'react';
import servicesDetailsImage1 from '../../../assets/img/features-details-image/1.png';

function ServiceDetails({data}) {
    return (
        <>
            <div className="services-details-area ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 services-details">
                            <div className="services-details-desc">
                                <h3>{data.subtitle}</h3>
                                <p>{data.description}</p>
                            </div>
                        </div>

                        <div className="col-lg-6 services-details-image">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src={servicesDetailsImage1} className="wow fadeInUp" alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceDetails;
