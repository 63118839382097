import React from 'react';
import Navbar from "../../layout/basic-components/Navbar";
import PageTitle from "../../layout/mini-components/service/PageTitle";
import Footer from "../../layout/basic-components/Footer";
import serviceData from '../../assets/json/ServiceData.json';
import ServiceDetails from "../../layout/mini-components/service/ServiceDetail";


function SingleService1() {
    return (
        <>
            <Navbar/>
            <PageTitle title={serviceData[0].title}/>
            <ServiceDetails data={serviceData[0]}/>
            <Footer/>
        </>
    );
}

export default SingleService1;
