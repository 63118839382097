import React from 'react';
import shape1 from '../../../assets/img/shape1.png';
import shape2 from '../../../assets/img/shape2.svg';
import shape3 from '../../../assets/img/shape3.svg';
import shape4 from '../../../assets/img/shape4.svg';
import shape5 from '../../../assets/img/shape5.png';
import shape6 from '../../../assets/img/shape4.svg';
import shape7 from '../../../assets/img/shape4.svg';
import shape8 from '../../../assets/img/shape2.svg';

const PageTitle = ({ title}) => {
    return (
        <div className="page-title-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <h2>{title}</h2>
                    </div>
                </div>
            </div>

            <div className="shape1"><img src={shape1} alt="shape" /></div>
            <div className="shape2 rotateme"><img src={shape2} alt="shape" /></div>
            <div className="shape3"><img src={shape3} alt="shape" /></div>
            <div className="shape4"><img src={shape4} alt="shape" /></div>
            <div className="shape5"><img src={shape5} alt="shape" /></div>
            <div className="shape6 rotateme"><img src={shape6} alt="shape" /></div>
            <div className="shape7"><img src={shape7} alt="shape" /></div>
            <div className="shape8 rotateme"><img src={shape8} alt="shape" /></div>
        </div>
    );
}

export default PageTitle;
