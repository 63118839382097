import React from 'react';
import Navbar from "../../layout/basic-components/Navbar";
import MainBanner from "../../layout/basic-components/MainBanner";
import BoxesArea from "../../layout/basic-components/BoxesArea";
import CloudHostingServices from "../../layout/basic-components/CloudHostingServices";
import DesignDevelopmentServices from "../../layout/basic-components/DesignDevelopmentServices";
import Footer from "../../layout/basic-components/Footer";
import BlogArea from "../../layout/basic-components/BlogArea";
import PartnerArea from "../../layout/basic-components/PartnerArea";
import ReadyToTalkArea from "../../layout/basic-components/ReadyToTalkArea";
import PricingArea from "../../layout/basic-components/PricingArea";
import WorksArea from "../../layout/basic-components/WorksArea";
import FunFactsArea from "../../layout/basic-components/FunFactsArea";
import TeamArea from "../../layout/basic-components/TeamArea";
import Testimonials from "../../layout/basic-components/Testimonials";

function Home() {
    return (
        <>
            <Navbar/>
            <MainBanner/>
            <BoxesArea/>
            <CloudHostingServices/>
            <DesignDevelopmentServices/>
            <TeamArea/>
            <FunFactsArea/>
            <WorksArea/>
            <PricingArea/>
            <Testimonials/>
            <ReadyToTalkArea/>
            <PartnerArea/>
            <BlogArea/>
            <Footer/>
        </>
    );
}

export default Home;
