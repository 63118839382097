import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import worksData from '../../assets/json/WorksData.json';
import {Settings} from "react-feather";


const WorksArea = () => {
    const worksSlides = worksData.map((work) => (
        <div className="single-works" key={work.id}>
            <img src={work.image} alt={`Image ${work.id}`} />
            <div className="icon"> <Settings/></div>
            <div className="works-content">
                <h3>{work.title}</h3>
                <p>{work.description}</p>
            </div>
        </div>
    ));

    return (
        <div className="works-area ptb-80 bg-f7fafd">
            <div className="container">
                <div className="section-title">
                    <h2>Our Recent Works</h2>
                    <div className="bar"></div>
                    <p>Explore our recent works showcasing innovative solutions and successful projects that demonstrate
                       our expertise and commitment to excellence.</p>
                </div>
            </div>

            <div className="container-fluid p-0">
                <OwlCarousel
                    className="works-slides owl-carousel owl-theme"
                    loop
                    margin={10}
                    nav
                    responsive={{
                        0: { items: 1 },
                        768: { items: 2 },
                        1200: { items: 3 },
                    }}
                >
                    {worksSlides}
                </OwlCarousel>
            </div>

            <div className="shape8 rotateme"><img src="/assets/img/shape2.svg" alt="shape" /></div>
            <div className="shape2 rotateme"><img src="/assets/img/shape2.svg" alt="shape" /></div>
            <div className="shape7"><img src="/assets/img/shape4.svg" alt="shape" /></div>
            <div className="shape4"><img src="/assets/img/shape4.svg" alt="shape" /></div>
        </div>
    );
}

export default WorksArea;
