import React from 'react';
import { Link } from 'react-router-dom';
import shape2 from '../../assets/img/shape2.svg';
import shape4 from '../../assets/img/shape4.svg';

const PricingArea = () => {
    return (
        <div className="pricing-area ptb-80 bg-f9f6f6">
            <div className="container">
                <div className="section-title">
                    <h2>Pricing Plans</h2>
                    <div className="bar"></div>
                    <p>Our pricing plans are flexible and scalable, catering to businesses of all sizes. Choose the
                        plan that best suits your needs and budget.</p>
                </div>

                <div className="container-fluid row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="pricing-table">
                            <div className="pricing-header">
                                <h3>SimpCash</h3>
                            </div>
                            <div className="price">
                                <span><sup>Rs</sup>2000 <span>/Mon</span></span><br/>
                                <br/><p className="d-flex justify-content-center">SimpCash Is Micro Finance Management Web Application Wih Loan Management And Ledger
                                Management</p>
                            </div>
                            <div className="pricing-features">
                                <ul>
                                    <li className="active fw-bold">5000 Rs Setup Fee</li>
                                    <li className="active">5 user</li>
                                    <li className="active">200 Borrower</li>
                                    <li className="active">Unlimited Loans</li>
                                    <li className="active">Penalty Management</li>
                                    <li className="active">Ledger Management</li>
                                    <li className="active">10 Reports</li>
                                </ul>
                            </div>
                            <div className="pricing-footer">
                                <Link to="#" className="btn btn-primary">Select Plan</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="pricing-table active-plan">
                            <div className="pricing-header">
                                <h3>HotelMate</h3>
                            </div>
                            <div className="price">
                                <span><sup>Rs</sup>10000 <span>/Mon</span></span><br/>
                                <br/><p className="d-flex justify-content-center">HotelMate is hotel management system with automated
                                invoices and restaurant management system</p>
                            </div>
                            <div className="pricing-features">
                                <ul>
                                    <li className="active fw-bold">20,000 Rs Setup Fee</li>
                                    <li className="active">15 Users</li>
                                    <li className="active">15 Rooms</li>
                                    <li className="active">Unlimited Bookings</li>
                                    <li className="active">Unlimited Invoices</li>
                                    <li className="active">KOT,BOT Management</li>
                                    <li className="active">Restaurant Management</li>
                                </ul>
                            </div>
                            <div className="pricing-footer">
                                <Link to="#" className="btn btn-primary">Select Plan</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="pricing-table">
                            <div className="pricing-header">
                                <h3>TourMate</h3>
                            </div>
                            <div className="price">
                                <span><sup>Rs</sup>15000 <span>/Mon</span></span><br/>
                                <br/><p className="d-flex justify-content-center">TourMate is travel agency management
                                CRM with budget calculating and automated invoice feature system</p>
                            </div>
                            <div className="pricing-features">
                                <ul>
                                    <li className="active fw-bold">50,000 Rs Setup Fee</li>
                                    <li className="active">25 Users</li>
                                    <li className="active">Unlimited Tours</li>
                                    <li className="active">Unlimited Invoices</li>
                                    <li className="active">Package Management</li>
                                    <li className="active">Itinerary Management</li>
                                    <li className="active">Automated Emails</li>
                                </ul>
                            </div>
                            <div className="pricing-footer">
                                <Link to="#" className="btn btn-primary">Select Plan</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape8 rotateme"><img src={shape2} alt="shape" /></div>
            <div className="shape2 rotateme"><img src={shape2} alt="shape" /></div>
            <div className="shape7"><img src={shape4} alt="shape" /></div>
            <div className="shape4"><img src={shape4} alt="shape" /></div>
        </div>
    );
}

export default PricingArea;
