import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./pages/home/Home";
import Service from "./pages/services/Service";
import SingleService1 from "./pages/services/SingleService1";
import Project from "./pages/Project";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Career from "./pages/career/Career";
import SingleJob from "./pages/career/SingleJob";
import SingleBlog from "./pages/home/SingleBlog";
import SingleService2 from "./pages/services/SingleService2";
import SingleService3 from "./pages/services/SingleService3";
import SingleService4 from "./pages/services/SingleService4";
import SingleService5 from "./pages/services/SingleService5";
import SingleService6 from "./pages/services/SingleService6";

function App() {
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/service" element={<Service />} >
                        <Route path="details" element={<SingleService1/>} />
                    </Route>

                    <Route path="/service1" element={<SingleService1 />}/>
                    <Route path="/service2" element={<SingleService2 />}/>
                    <Route path="/service3" element={<SingleService3 />}/>
                    <Route path="/service4" element={<SingleService4 />}/>
                    <Route path="/service5" element={<SingleService5 />}/>
                    <Route path="/service6" element={<SingleService6 />}/>
                    <Route path="/projects" element={<Project />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/contact" element={<Contact />}/>
                    <Route path="/career" element={<Career />}/>
                    <Route path="/job1" element={<SingleJob />}/>
                    <Route path="/blog1" element={<SingleBlog />}/>

                </Routes>
            </div>
        </Router>
    );
}

export default App;
