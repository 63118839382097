import React, { useState } from 'react';
import Navbar from "../../layout/basic-components/Navbar";
import PageTitle from "../../layout/mini-components/service/PageTitle";
import Footer from "../../layout/basic-components/Footer";
import jobDetailsData from '../../assets/json/JobData.json';
import servicesDetailsImage1 from '../../assets/img/services-details-image/1.png';
import servicesDetailsImage2 from '../../assets/img/services-details-image/2.png';
import 'animate.css';

function SingleJob() {
    const [accordionActive, setAccordionActive] = useState(0);

    const toggleAccordion = (index) => {
        if (accordionActive === index) {
            setAccordionActive(null);
        } else {
            setAccordionActive(index);
        }
    };

    return (
        <>
            <Navbar/>
            <PageTitle title={jobDetailsData.title}/>
            <div className="services-details-area ptb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 services-details">
                            <div className="services-details-desc">
                                <h3>Job Description: </h3>
                                <p>{jobDetailsData.description}</p>
                                <div className='row'>
                                    <p className="fw-bold col-6">Location: {jobDetailsData.location}</p>
                                    <p className="fw-bold col-6">Position Type: {jobDetailsData.positionType}</p><br/>
                                </div><br/>
                                <h3>Responsibilities: </h3>
                                <ul>
                                    {jobDetailsData.responsibilities.map((responsibility, index) => (
                                        <li key={index}>{responsibility}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-6 services-details-image">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src={servicesDetailsImage1} className="wow fadeInUp" alt="image" />
                        </div>
                    </div>

                    <div className="separate"></div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 services-details-image">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src={servicesDetailsImage2} className="wow fadeInUp" alt="image"/>
                        </div>

                        <div className="col-lg-6 services-details">
                            <div className="services-details-desc">
                                <h3>Requirements: </h3>
                                <ul>
                                    {jobDetailsData.requirements.map((requirement, index) => (
                                        <li key={index}>{requirement}</li>
                                    ))}
                                </ul>

                                <div className="services-details-accordion">
                                    <ul className="accordion">
                                        <li className={`accordion-item ${accordionActive === 1 ? 'active' : ''}`}>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a className="accordion-title" onClick={() => toggleAccordion(1)}>
                                                <i className="flaticon-plus"></i>
                                                Benefits:
                                            </a>

                                            <p className={`accordion-content ${accordionActive === 1 ? 'show' : ''}`}>
                                                {jobDetailsData.benefits.map((benefit, index) => (
                                                    <span key={index}>{benefit}<br/></span>
                                                ))}
                                            </p>
                                        </li>

                                        <li className={`accordion-item ${accordionActive === 2 ? 'active' : ''}`}>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a className="accordion-title" onClick={() => toggleAccordion(2)}>
                                                <i className="flaticon-plus"></i>
                                                How to Apply:
                                            </a>

                                            <p className={`accordion-content ${accordionActive === 2 ? 'show' : ''}`}>
                                                {jobDetailsData.howToApply}
                                            </p>
                                        </li>

                                        <li className={`accordion-item ${accordionActive === 3 ? 'active' : ''}`}>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a className="accordion-title" onClick={() => toggleAccordion(3)}>
                                                <i className="flaticon-plus"></i>
                                                Application Deadline and Note:
                                            </a>
                                            <p className={`accordion-content ${accordionActive === 3 ? 'show' : ''}`}>
                                                Application Deadline: {jobDetailsData.applicationDeadline} <br/>
                                                Note: {jobDetailsData.note}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default SingleJob;
